import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Layout = ({ data, location, title, children }) => {
  
  return (
    <div className="container-fluid">
      <div className="container">

        <header>
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link to={`/`} className="navbar-brand">
              <Img fixed={data.picture.childImageSharp.fixed} className="d-inline-block align-top rounded-circle" alt="Alexander Petkov Portrait" />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
              <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                <li className="nav-item">
                  <Link to="/cv" className="nav-link"><span role="img" aria-label="cv">📄</span> Curriculum Vitae</Link>
                </li>
                <li className="nav-item">
                  <Link to="/books" className="nav-link"><span role="img" aria-label="book">📖</span> Book Recommondations</Link>
                </li>
                <li className="nav-item">
                  <Link to="/startup-toolbox" className="nav-link"><span role="img" aria-label="book">🚀</span> Startup Toolbox</Link>
                </li>
              </ul>
            </div>
          </nav>
        </header>

        <main>{children}</main>

        <footer className="mb-5 mt-5">
          <hr />
          © {new Date().getFullYear()}, Built in 🇦🇹 with ❤ and 🍻
        </footer>

      </div>
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
    query {
      picture: file( relativePath: { eq: "profile-pic.jpg" } ) {
        childImageSharp{
          fixed(width: 48, height: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    `}
    render={data => <Layout data={data} {...props} />}
  />
)
